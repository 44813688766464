import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { from, switchMap } from 'rxjs';
import { isReallyOnline } from '@wheere-front/shared-util';

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const service = inject(KeycloakService);

  return from(isReallyOnline()).pipe(
    switchMap(online => {
      if (!online) {
        return next(req);
      }

      return from(service.getToken()).pipe(
        switchMap(token => {
          if (!token) {
            return next(req);
          }

          const newReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          });

          return next(newReq);
        })
      );
    })
  );
}
