import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { AuthStore } from './auth.store';
import { isReallyOnline } from '@wheere-front/shared-util';

/**
 * Guard 1: userIsSuperAdmin
 * Example: if the user isn't super admin, go to /forbidden
 * This example does NOT check connectivity (you can add it if you wish).
 */
export function userIsSuperAdmin(redirectUrl = '/forbidden'): CanActivateFn {
  return () => {
    const isSuperAdmin = inject(AuthStore).isSuperAdmin();

    if (isSuperAdmin) {
      return true;
    }

    return inject(Router).parseUrl(redirectUrl);
  };
}

/**
 * Guard 2: userHasAnyRole
 * If offline => allow (true).
 * If online => check roles; else redirect to /forbidden
 */
export function userHasAnyRole(roles: string | string[], redirectUrl = '/forbidden'): CanActivateFn {
  return async () => {
    const router = inject(Router);
    const authStore = inject(AuthStore);

    // Check true online status via ping
    const online = await isReallyOnline();
    // If truly offline => allow
    if (!online) {
      return true;
    }

    // If online => check if user has any required role
    const hasAnyRole = authStore.hasAnyRole(roles);
    if (hasAnyRole) {
      return true;
    }

    // Otherwise, redirect
    return router.parseUrl(redirectUrl);
  };
}

/**
 * Guard 3: authenticationGuard
 * If offline => allow (true).
 * If online => check if logged in; else do Keycloak login.
 */
export function authenticationGuard(): CanActivateFn {
  return async (_, state: RouterStateSnapshot) => {
    const service = inject(KeycloakService);
    const documentWindow = inject(DOCUMENT).defaultView;

    // Check true online status via ping
    const online = await isReallyOnline();
    if (!online) {
      // If truly offline => allow the route
      return true;
    }

    // If online => check if user is logged in
    const loggedIn = await service.isLoggedIn();
    if (loggedIn) {
      if (loggedIn) {
        return true;
      }
      return true;
    }

    // If not logged in => initiate login
    service.login({
      redirectUri: documentWindow?.location.origin + state.url,
      scope: 'openid offline_access',
    });

    // The guard must return false or a UrlTree to block navigation
    return false;
  };
}
